.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: auto !important;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px; /* Optional: Add rounded corners */
}

.swiper-button-next,
.swiper-button-prev {
  color: white; /* Customize arrow color if needed */
}
